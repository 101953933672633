//import logo from './logo.svg';
import './App.css';
import WalletCardEthers from'./WalletCardEthers';

import { initializeApp } from "firebase/compat/app";
import firebase from 'firebase/compat/app'; //v9
import 'firebase/compat/firestore'; //v9
import { getStorage } from "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyBEsITn9HDkTHmfpzcQmqwXf5SqLmWM120",
  authDomain: "app-kingdom.firebaseapp.com",
  projectId: "app-kingdom",
  storageBucket: "app-kingdom.appspot.com",
  messagingSenderId: "884289190932",
  appId: "1:884289190932:web:18939f6157e2e88894bb01",
  measurementId: "${config.measurementId}"
};



function App() {
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  
  var db = firebase.firestore()
  const storage = getStorage(firebaseApp);  
  
  return (
    <div className="App">
      <WalletCardEthers db={db} storage={storage} />
    </div>
  );
}

export default App;