import React, {useState, useEffect} from 'react'
import './Button.css'

const Button = ({value, type, active, onClickFunc, locked}) => {
    let additionalClasses = "";
    if (type == "secondary") {
        additionalClasses += "button-secondary ";
    }else if (type == "regular") {
        additionalClasses += "button-regular ";
    }
    if (!active) {
        additionalClasses += "inactive ";
    }
    if (locked) {
        additionalClasses += "button-locked "
    }
    return (
        <div onClick={onClickFunc} className={`button-container ${additionalClasses}`}>
            {value}
        </div>
    );
}

export default Button;