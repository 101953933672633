import React from 'react'
import Artwork from './Artwork'

const GetArtworks = ({artworks, clickedArtwork}) => {
    console.log('artworks : ', artworks)

    var firstThreeWereDisplayed = false;

    var artworksPaired = [];

    for (var i = 0; i < artworks.length; i+=4) {
        artworksPaired.push([artworks[i], artworks[i+1], artworks[i+2], artworks[i+3]]);
    }

    return (
        <>
            {artworksPaired.map((artwork, i) => {
                return <div className="gallery-line"> 
                    <div className="gallery-subline">
                        { artwork[0] ? <Artwork idx={i} artworks={artworks} onClickFunc={clickedArtwork} place={i === 0 ? 1 : null}></Artwork> : <></>}
                        { artwork[1] ? <Artwork idx={i+1} artworks={artworks} onClickFunc={clickedArtwork} place={i+1 === 1 ? 2 : null}></Artwork> : <></>}
                    </div>
                    <div className="gallery-subline">
                        { artwork[2] ? <Artwork idx={i+2} artworks={artworks} onClickFunc={clickedArtwork} place={i+2 === 2 ? 3 : null}></Artwork> : <></>}
                        { artwork[3] ? <Artwork idx={i+3} artworks={artworks} onClickFunc={clickedArtwork} ></Artwork> : <></>}
                    </div>
                </div>
            })}
        </>
    )
}

export default GetArtworks