import React, {useState, useEffect} from 'react'
import {Contract, ethers} from 'ethers'
import './WalletCard.css'
import firebase from 'firebase/compat/app'; //v9
import 'firebase/compat/firestore'; //v9
import { getStorage, ref, uploadBytes } from "firebase/storage";


import Button from "./Button"
import Artwork from './Artwork';
import ArtworkExpanded from './ArtworkExpanded';
import Form from './Form'
import GetArtworks from './GetArtworks'
//var db = firebase.firestore()

const WalletCardEthers = ({db, storage}) => {	
    const [welcomeMessage, setWelcomeMessage] = useState("Please connect to Web3...");
    const [connectWalletActive, setConnectWalletActive] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);
	const [defaultAccount, setDefaultAccount] = useState(null);
	const [userBalance, setUserBalance] = useState(null);
	const [connButtonText, setConnButtonText] = useState('Connect Wallet');
	const [provider, setProvider] = useState(null);
	const [nftBalance, setNFTBalance] = useState(0)
	const [authorized, setAuthorized] = useState(false)
	const [formData, setFormData] = useState(Object())
	const [loading, setLoading] = useState(false)
    const [artworks, setArtworks] = useState([]);
    const [artworkIdx, setArtworkIdx] = useState(0);
	const [holderData, setHolderData] = useState()

    const [alreadyCreated, setAlreadyCreated] = useState(false);

    const [toggled, setToggled] = useState(true);

    var firstThreeWereDisplayed = false;	
	const connectWalletHandler = () => {
		if (window.ethereum && defaultAccount == null) {
			// set ethers provider
			setProvider(new ethers.providers.Web3Provider(window.ethereum));

			// connect to metamask
			window.ethereum.request({ method: 'eth_requestAccounts'})
			.then(result => {
				setConnButtonText('Wallet Connected');
				setDefaultAccount(result[0]);
				
			})
			.catch(error => {
				setErrorMessage(error.message);
			});

		} else if (!window.ethereum){
			console.log('Need to install MetaMask');
			setErrorMessage('Please install MetaMask browser extension to interact');
		}
	}
	const validateFieldsAndSendFb = () => {
		setLoading(true)
		var title = document.getElementById('title');
		var description = document.getElementById('description');
		var file = document.getElementById('file').files[0];

		if (title.value != "" && description.value != "" && file != "") {
			var dataFields = {
				title: title.value,
				description: description.value,
				file:file
			}

			const storageRef = ref(storage, file.name);
	
			uploadBytes(storageRef, file).then((snapshot) => {
				var today_date = new Date()
				var today = `${today_date.getUTCDate()}_${today_date.getMonth()+1}_${today_date.getFullYear()}`
				
				db.collection('holders').doc(defaultAccount).update({
					'path': `${file.name}`,
					'type': `${file.type}`,
					'crowns': 0,
					'votes': 0,
					'date': today,
					'title': title.value,
					'description': description.value
				})
			});
			
			setFormData(dataFields)
			setLoading(false)
			clickCreateModal()
		}		
	}

	const LoadingComponent = ({loading}) => {
		if (loading) {
			return <>Loading...</>
		} else {
			return <></>
		}
	}

	const AuthorizedContent = ({authorized}) => {
		if (authorized) {
			return <h3>Bonjour les Kings !</h3>
		} else {
			return <h3>Bonjour les moldus !</h3>
		}
	}

	const BOOBA_NFT = {
		adress: "0x3B7335f3F1771122cd0107416b1DA1b2FB7E94dD",
		abi:[
			{
			   "inputs":[
				  {
					 "internalType":"string",
					 "name":"uri",
					 "type":"string"
				  },
				  {
					 "internalType":"string",
					 "name":"contractUri",
					 "type":"string"
				  }
			   ],
			   "stateMutability":"nonpayable",
			   "type":"constructor"
			},
			{
			   "anonymous":false,
			   "inputs":[
				  {
					 "indexed":true,
					 "internalType":"address",
					 "name":"account",
					 "type":"address"
				  },
				  {
					 "indexed":true,
					 "internalType":"address",
					 "name":"operator",
					 "type":"address"
				  },
				  {
					 "indexed":false,
					 "internalType":"bool",
					 "name":"approved",
					 "type":"bool"
				  }
			   ],
			   "name":"ApprovalForAll",
			   "type":"event"
			},
			{
			   "anonymous":false,
			   "inputs":[
				  {
					 "indexed":true,
					 "internalType":"address",
					 "name":"previousOwner",
					 "type":"address"
				  },
				  {
					 "indexed":true,
					 "internalType":"address",
					 "name":"newOwner",
					 "type":"address"
				  }
			   ],
			   "name":"OwnershipTransferred",
			   "type":"event"
			},
			{
			   "anonymous":false,
			   "inputs":[
				  {
					 "indexed":false,
					 "internalType":"address",
					 "name":"account",
					 "type":"address"
				  }
			   ],
			   "name":"Paused",
			   "type":"event"
			},
			{
			   "anonymous":false,
			   "inputs":[
				  {
					 "indexed":true,
					 "internalType":"bytes32",
					 "name":"role",
					 "type":"bytes32"
				  },
				  {
					 "indexed":true,
					 "internalType":"bytes32",
					 "name":"previousAdminRole",
					 "type":"bytes32"
				  },
				  {
					 "indexed":true,
					 "internalType":"bytes32",
					 "name":"newAdminRole",
					 "type":"bytes32"
				  }
			   ],
			   "name":"RoleAdminChanged",
			   "type":"event"
			},
			{
			   "anonymous":false,
			   "inputs":[
				  {
					 "indexed":true,
					 "internalType":"bytes32",
					 "name":"role",
					 "type":"bytes32"
				  },
				  {
					 "indexed":true,
					 "internalType":"address",
					 "name":"account",
					 "type":"address"
				  },
				  {
					 "indexed":true,
					 "internalType":"address",
					 "name":"sender",
					 "type":"address"
				  }
			   ],
			   "name":"RoleGranted",
			   "type":"event"
			},
			{
			   "anonymous":false,
			   "inputs":[
				  {
					 "indexed":true,
					 "internalType":"bytes32",
					 "name":"role",
					 "type":"bytes32"
				  },
				  {
					 "indexed":true,
					 "internalType":"address",
					 "name":"account",
					 "type":"address"
				  },
				  {
					 "indexed":true,
					 "internalType":"address",
					 "name":"sender",
					 "type":"address"
				  }
			   ],
			   "name":"RoleRevoked",
			   "type":"event"
			},
			{
			   "anonymous":false,
			   "inputs":[
				  {
					 "indexed":true,
					 "internalType":"address",
					 "name":"operator",
					 "type":"address"
				  },
				  {
					 "indexed":true,
					 "internalType":"address",
					 "name":"from",
					 "type":"address"
				  },
				  {
					 "indexed":true,
					 "internalType":"address",
					 "name":"to",
					 "type":"address"
				  },
				  {
					 "indexed":false,
					 "internalType":"uint256[]",
					 "name":"ids",
					 "type":"uint256[]"
				  },
				  {
					 "indexed":false,
					 "internalType":"uint256[]",
					 "name":"values",
					 "type":"uint256[]"
				  }
			   ],
			   "name":"TransferBatch",
			   "type":"event"
			},
			{
			   "anonymous":false,
			   "inputs":[
				  {
					 "indexed":true,
					 "internalType":"address",
					 "name":"operator",
					 "type":"address"
				  },
				  {
					 "indexed":true,
					 "internalType":"address",
					 "name":"from",
					 "type":"address"
				  },
				  {
					 "indexed":true,
					 "internalType":"address",
					 "name":"to",
					 "type":"address"
				  },
				  {
					 "indexed":false,
					 "internalType":"uint256",
					 "name":"id",
					 "type":"uint256"
				  },
				  {
					 "indexed":false,
					 "internalType":"uint256",
					 "name":"value",
					 "type":"uint256"
				  }
			   ],
			   "name":"TransferSingle",
			   "type":"event"
			},
			{
			   "anonymous":false,
			   "inputs":[
				  {
					 "indexed":false,
					 "internalType":"string",
					 "name":"value",
					 "type":"string"
				  },
				  {
					 "indexed":true,
					 "internalType":"uint256",
					 "name":"id",
					 "type":"uint256"
				  }
			   ],
			   "name":"URI",
			   "type":"event"
			},
			{
			   "anonymous":false,
			   "inputs":[
				  {
					 "indexed":false,
					 "internalType":"address",
					 "name":"account",
					 "type":"address"
				  }
			   ],
			   "name":"Unpaused",
			   "type":"event"
			},
			{
			   "inputs":[
				  
			   ],
			   "name":"DEFAULT_ADMIN_ROLE",
			   "outputs":[
				  {
					 "internalType":"bytes32",
					 "name":"",
					 "type":"bytes32"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  
			   ],
			   "name":"MINTER_ROLE",
			   "outputs":[
				  {
					 "internalType":"bytes32",
					 "name":"",
					 "type":"bytes32"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  
			   ],
			   "name":"PAUSER_ROLE",
			   "outputs":[
				  {
					 "internalType":"bytes32",
					 "name":"",
					 "type":"bytes32"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"address",
					 "name":"account",
					 "type":"address"
				  },
				  {
					 "internalType":"uint256",
					 "name":"id",
					 "type":"uint256"
				  }
			   ],
			   "name":"balanceOf",
			   "outputs":[
				  {
					 "internalType":"uint256",
					 "name":"",
					 "type":"uint256"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"address[]",
					 "name":"accounts",
					 "type":"address[]"
				  },
				  {
					 "internalType":"uint256[]",
					 "name":"ids",
					 "type":"uint256[]"
				  }
			   ],
			   "name":"balanceOfBatch",
			   "outputs":[
				  {
					 "internalType":"uint256[]",
					 "name":"",
					 "type":"uint256[]"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"address",
					 "name":"account",
					 "type":"address"
				  },
				  {
					 "internalType":"uint256",
					 "name":"id",
					 "type":"uint256"
				  },
				  {
					 "internalType":"uint256",
					 "name":"value",
					 "type":"uint256"
				  }
			   ],
			   "name":"burn",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"address",
					 "name":"account",
					 "type":"address"
				  },
				  {
					 "internalType":"uint256[]",
					 "name":"ids",
					 "type":"uint256[]"
				  },
				  {
					 "internalType":"uint256[]",
					 "name":"values",
					 "type":"uint256[]"
				  }
			   ],
			   "name":"burnBatch",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  
			   ],
			   "name":"contractURI",
			   "outputs":[
				  {
					 "internalType":"string",
					 "name":"",
					 "type":"string"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"uint256",
					 "name":"",
					 "type":"uint256"
				  }
			   ],
			   "name":"getFeeBps",
			   "outputs":[
				  {
					 "internalType":"uint256[]",
					 "name":"bps",
					 "type":"uint256[]"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"uint256",
					 "name":"",
					 "type":"uint256"
				  }
			   ],
			   "name":"getFeeRecipients",
			   "outputs":[
				  {
					 "internalType":"address payable[]",
					 "name":"recipients",
					 "type":"address[]"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"bytes32",
					 "name":"role",
					 "type":"bytes32"
				  }
			   ],
			   "name":"getRoleAdmin",
			   "outputs":[
				  {
					 "internalType":"bytes32",
					 "name":"",
					 "type":"bytes32"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"bytes32",
					 "name":"role",
					 "type":"bytes32"
				  },
				  {
					 "internalType":"uint256",
					 "name":"index",
					 "type":"uint256"
				  }
			   ],
			   "name":"getRoleMember",
			   "outputs":[
				  {
					 "internalType":"address",
					 "name":"",
					 "type":"address"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"bytes32",
					 "name":"role",
					 "type":"bytes32"
				  }
			   ],
			   "name":"getRoleMemberCount",
			   "outputs":[
				  {
					 "internalType":"uint256",
					 "name":"",
					 "type":"uint256"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"uint256",
					 "name":"",
					 "type":"uint256"
				  }
			   ],
			   "name":"getRoyalties",
			   "outputs":[
				  {
					 "internalType":"address payable[]",
					 "name":"recipients",
					 "type":"address[]"
				  },
				  {
					 "internalType":"uint256[]",
					 "name":"bps",
					 "type":"uint256[]"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"bytes32",
					 "name":"role",
					 "type":"bytes32"
				  },
				  {
					 "internalType":"address",
					 "name":"account",
					 "type":"address"
				  }
			   ],
			   "name":"grantRole",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"bytes32",
					 "name":"role",
					 "type":"bytes32"
				  },
				  {
					 "internalType":"address",
					 "name":"account",
					 "type":"address"
				  }
			   ],
			   "name":"hasRole",
			   "outputs":[
				  {
					 "internalType":"bool",
					 "name":"",
					 "type":"bool"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"address",
					 "name":"account",
					 "type":"address"
				  },
				  {
					 "internalType":"address",
					 "name":"operator",
					 "type":"address"
				  }
			   ],
			   "name":"isApprovedForAll",
			   "outputs":[
				  {
					 "internalType":"bool",
					 "name":"",
					 "type":"bool"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"address",
					 "name":"to",
					 "type":"address"
				  },
				  {
					 "internalType":"uint256",
					 "name":"id",
					 "type":"uint256"
				  },
				  {
					 "internalType":"uint256",
					 "name":"amount",
					 "type":"uint256"
				  },
				  {
					 "internalType":"bytes",
					 "name":"data",
					 "type":"bytes"
				  }
			   ],
			   "name":"mint",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"address",
					 "name":"to",
					 "type":"address"
				  },
				  {
					 "internalType":"uint256[]",
					 "name":"ids",
					 "type":"uint256[]"
				  },
				  {
					 "internalType":"uint256[]",
					 "name":"amounts",
					 "type":"uint256[]"
				  },
				  {
					 "internalType":"bytes",
					 "name":"data",
					 "type":"bytes"
				  }
			   ],
			   "name":"mintBatch",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  
			   ],
			   "name":"name",
			   "outputs":[
				  {
					 "internalType":"string",
					 "name":"",
					 "type":"string"
				  }
			   ],
			   "stateMutability":"pure",
			   "type":"function"
			},
			{
			   "inputs":[
				  
			   ],
			   "name":"owner",
			   "outputs":[
				  {
					 "internalType":"address",
					 "name":"",
					 "type":"address"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  
			   ],
			   "name":"pause",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  
			   ],
			   "name":"paused",
			   "outputs":[
				  {
					 "internalType":"bool",
					 "name":"",
					 "type":"bool"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  
			   ],
			   "name":"renounceOwnership",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"bytes32",
					 "name":"role",
					 "type":"bytes32"
				  },
				  {
					 "internalType":"address",
					 "name":"account",
					 "type":"address"
				  }
			   ],
			   "name":"renounceRole",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"bytes32",
					 "name":"role",
					 "type":"bytes32"
				  },
				  {
					 "internalType":"address",
					 "name":"account",
					 "type":"address"
				  }
			   ],
			   "name":"revokeRole",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"uint256",
					 "name":"",
					 "type":"uint256"
				  },
				  {
					 "internalType":"uint256",
					 "name":"value",
					 "type":"uint256"
				  }
			   ],
			   "name":"royaltyInfo",
			   "outputs":[
				  {
					 "internalType":"address",
					 "name":"",
					 "type":"address"
				  },
				  {
					 "internalType":"uint256",
					 "name":"",
					 "type":"uint256"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"address",
					 "name":"from",
					 "type":"address"
				  },
				  {
					 "internalType":"address",
					 "name":"to",
					 "type":"address"
				  },
				  {
					 "internalType":"uint256[]",
					 "name":"ids",
					 "type":"uint256[]"
				  },
				  {
					 "internalType":"uint256[]",
					 "name":"amounts",
					 "type":"uint256[]"
				  },
				  {
					 "internalType":"bytes",
					 "name":"data",
					 "type":"bytes"
				  }
			   ],
			   "name":"safeBatchTransferFrom",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"address",
					 "name":"from",
					 "type":"address"
				  },
				  {
					 "internalType":"address",
					 "name":"to",
					 "type":"address"
				  },
				  {
					 "internalType":"uint256",
					 "name":"id",
					 "type":"uint256"
				  },
				  {
					 "internalType":"uint256",
					 "name":"amount",
					 "type":"uint256"
				  },
				  {
					 "internalType":"bytes",
					 "name":"data",
					 "type":"bytes"
				  }
			   ],
			   "name":"safeTransferFrom",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"address",
					 "name":"operator",
					 "type":"address"
				  },
				  {
					 "internalType":"bool",
					 "name":"approved",
					 "type":"bool"
				  }
			   ],
			   "name":"setApprovalForAll",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"string",
					 "name":"uri",
					 "type":"string"
				  }
			   ],
			   "name":"setRevealedURI",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"bytes4",
					 "name":"interfaceId",
					 "type":"bytes4"
				  }
			   ],
			   "name":"supportsInterface",
			   "outputs":[
				  {
					 "internalType":"bool",
					 "name":"",
					 "type":"bool"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			},
			{
			   "inputs":[
				  
			   ],
			   "name":"symbol",
			   "outputs":[
				  {
					 "internalType":"string",
					 "name":"",
					 "type":"string"
				  }
			   ],
			   "stateMutability":"pure",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"address",
					 "name":"newOwner",
					 "type":"address"
				  }
			   ],
			   "name":"transferOwnership",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  
			   ],
			   "name":"unpause",
			   "outputs":[
				  
			   ],
			   "stateMutability":"nonpayable",
			   "type":"function"
			},
			{
			   "inputs":[
				  {
					 "internalType":"uint256",
					 "name":"",
					 "type":"uint256"
				  }
			   ],
			   "name":"uri",
			   "outputs":[
				  {
					 "internalType":"string",
					 "name":"",
					 "type":"string"
				  }
			   ],
			   "stateMutability":"view",
			   "type":"function"
			}
		 ]
	}

	const pianoKingNFT = {
		address: "0x725afA0C34bab44f5b1ef8f87c50438F934c1A85",
		abi: [
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "_pianoKingWhitelistAddress",
				  "type": "address"
				},
				{
				  "internalType": "address",
				  "name": "_pianoKingRNConsumer",
				  "type": "address"
				},
				{
				  "internalType": "address",
				  "name": "_pianoKingFunds",
				  "type": "address"
				}
			  ],
			  "stateMutability": "nonpayable",
			  "type": "constructor"
			},
			{
			  "anonymous": false,
			  "inputs": [
				{
				  "indexed": true,
				  "internalType": "address",
				  "name": "owner",
				  "type": "address"
				},
				{
				  "indexed": true,
				  "internalType": "address",
				  "name": "approved",
				  "type": "address"
				},
				{
				  "indexed": true,
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "Approval",
			  "type": "event"
			},
			{
			  "anonymous": false,
			  "inputs": [
				{
				  "indexed": true,
				  "internalType": "address",
				  "name": "owner",
				  "type": "address"
				},
				{
				  "indexed": true,
				  "internalType": "address",
				  "name": "operator",
				  "type": "address"
				},
				{
				  "indexed": false,
				  "internalType": "bool",
				  "name": "approved",
				  "type": "bool"
				}
			  ],
			  "name": "ApprovalForAll",
			  "type": "event"
			},
			{
			  "anonymous": false,
			  "inputs": [
				{
				  "indexed": true,
				  "internalType": "address",
				  "name": "previousOwner",
				  "type": "address"
				},
				{
				  "indexed": true,
				  "internalType": "address",
				  "name": "newOwner",
				  "type": "address"
				}
			  ],
			  "name": "OwnershipTransferred",
			  "type": "event"
			},
			{
			  "anonymous": false,
			  "inputs": [
				{
				  "indexed": true,
				  "internalType": "address",
				  "name": "from",
				  "type": "address"
				},
				{
				  "indexed": true,
				  "internalType": "address",
				  "name": "to",
				  "type": "address"
				},
				{
				  "indexed": true,
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "Transfer",
			  "type": "event"
			},
			{
			  "inputs": [],
			  "name": "MIN_PRICE",
			  "outputs": [
				{
				  "internalType": "uint256",
				  "name": "",
				  "type": "uint256"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "to",
				  "type": "address"
				},
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "approve",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "owner",
				  "type": "address"
				}
			  ],
			  "name": "balanceOf",
			  "outputs": [
				{
				  "internalType": "uint256",
				  "name": "",
				  "type": "uint256"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "baseURI",
			  "outputs": [
				{
				  "internalType": "string",
				  "name": "",
				  "type": "string"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "uint256",
				  "name": "count",
				  "type": "uint256"
				}
			  ],
			  "name": "batchMint",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "getApproved",
			  "outputs": [
				{
				  "internalType": "address",
				  "name": "",
				  "type": "address"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "getPianoKingWallet",
			  "outputs": [
				{
				  "internalType": "address",
				  "name": "",
				  "type": "address"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "getPremintAddresses",
			  "outputs": [
				{
				  "internalType": "address[]",
				  "name": "",
				  "type": "address[]"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "owner",
				  "type": "address"
				},
				{
				  "internalType": "address",
				  "name": "operator",
				  "type": "address"
				}
			  ],
			  "name": "isApprovedForAll",
			  "outputs": [
				{
				  "internalType": "bool",
				  "name": "",
				  "type": "bool"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "name",
			  "outputs": [
				{
				  "internalType": "string",
				  "name": "",
				  "type": "string"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "owner",
			  "outputs": [
				{
				  "internalType": "address",
				  "name": "",
				  "type": "address"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "ownerOf",
			  "outputs": [
				{
				  "internalType": "address",
				  "name": "",
				  "type": "address"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "pianoKingDutchAuction",
			  "outputs": [
				{
				  "internalType": "address",
				  "name": "",
				  "type": "address"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "pianoKingFunds",
			  "outputs": [
				{
				  "internalType": "address",
				  "name": "",
				  "type": "address"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "pianoKingRNConsumer",
			  "outputs": [
				{
				  "internalType": "contract IPianoKingRNConsumer",
				  "name": "",
				  "type": "address"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "pianoKingWallet",
			  "outputs": [
				{
				  "internalType": "address",
				  "name": "",
				  "type": "address"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "pianoKingWhitelist",
			  "outputs": [
				{
				  "internalType": "contract PianoKingWhitelist",
				  "name": "",
				  "type": "address"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "preMint",
			  "outputs": [],
			  "stateMutability": "payable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "uint256",
				  "name": "",
				  "type": "uint256"
				}
			  ],
			  "name": "preMintAddresses",
			  "outputs": [
				{
				  "internalType": "address",
				  "name": "",
				  "type": "address"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "",
				  "type": "address"
				}
			  ],
			  "name": "preMintAllowance",
			  "outputs": [
				{
				  "internalType": "uint256",
				  "name": "",
				  "type": "uint256"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "addr",
				  "type": "address"
				}
			  ],
			  "name": "preMintFor",
			  "outputs": [],
			  "stateMutability": "payable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "uint256",
				  "name": "count",
				  "type": "uint256"
				}
			  ],
			  "name": "presaleMint",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "renounceOwnership",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "retrieveFunds",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "salePrice",
				  "type": "uint256"
				}
			  ],
			  "name": "royaltyInfo",
			  "outputs": [
				{
				  "internalType": "address",
				  "name": "receiver",
				  "type": "address"
				},
				{
				  "internalType": "uint256",
				  "name": "royaltyAmount",
				  "type": "uint256"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "from",
				  "type": "address"
				},
				{
				  "internalType": "address",
				  "name": "to",
				  "type": "address"
				},
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "safeTransferFrom",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "from",
				  "type": "address"
				},
				{
				  "internalType": "address",
				  "name": "to",
				  "type": "address"
				},
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				},
				{
				  "internalType": "bytes",
				  "name": "_data",
				  "type": "bytes"
				}
			  ],
			  "name": "safeTransferFrom",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "operator",
				  "type": "address"
				},
				{
				  "internalType": "bool",
				  "name": "approved",
				  "type": "bool"
				}
			  ],
			  "name": "setApprovalForAll",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "string",
				  "name": "uri",
				  "type": "string"
				}
			  ],
			  "name": "setBaseURI",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "addr",
				  "type": "address"
				}
			  ],
			  "name": "setDutchAuction",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "addr",
				  "type": "address"
				}
			  ],
			  "name": "setFundsContract",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "addr",
				  "type": "address"
				}
			  ],
			  "name": "setPianoKingWallet",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address[]",
				  "name": "addrs",
				  "type": "address[]"
				},
				{
				  "internalType": "uint256[]",
				  "name": "amounts",
				  "type": "uint256[]"
				}
			  ],
			  "name": "setPreApprovedAddresses",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "addr",
				  "type": "address"
				}
			  ],
			  "name": "setRNConsumerContract",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "addr",
				  "type": "address"
				}
			  ],
			  "name": "setWhitelist",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "supplyLeft",
			  "outputs": [
				{
				  "internalType": "uint256",
				  "name": "",
				  "type": "uint256"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "bytes4",
				  "name": "interfaceId",
				  "type": "bytes4"
				}
			  ],
			  "name": "supportsInterface",
			  "outputs": [
				{
				  "internalType": "bool",
				  "name": "",
				  "type": "bool"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "symbol",
			  "outputs": [
				{
				  "internalType": "string",
				  "name": "",
				  "type": "string"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "tokenURI",
			  "outputs": [
				{
				  "internalType": "string",
				  "name": "",
				  "type": "string"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "totalSupply",
			  "outputs": [
				{
				  "internalType": "uint256",
				  "name": "",
				  "type": "uint256"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "from",
				  "type": "address"
				},
				{
				  "internalType": "address",
				  "name": "to",
				  "type": "address"
				},
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "transferFrom",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "newOwner",
				  "type": "address"
				}
			  ],
			  "name": "transferOwnership",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			}
		  ]
	}
	const b2o = {
		address: "0xd61ed516b218884b11760b85b188fa3230759c6a",
		abi: [
			{
			  "inputs": [
				{
				  "internalType": "string",
				  "name": "name_",
				  "type": "string"
				},
				{
				  "internalType": "string",
				  "name": "symbol_",
				  "type": "string"
				}
			  ],
			  "stateMutability": "nonpayable",
			  "type": "constructor"
			},
			{
			  "anonymous": false,
			  "inputs": [
				{
				  "indexed": true,
				  "internalType": "address",
				  "name": "owner",
				  "type": "address"
				},
				{
				  "indexed": true,
				  "internalType": "address",
				  "name": "approved",
				  "type": "address"
				},
				{
				  "indexed": true,
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "Approval",
			  "type": "event"
			},
			{
			  "anonymous": false,
			  "inputs": [
				{
				  "indexed": true,
				  "internalType": "address",
				  "name": "owner",
				  "type": "address"
				},
				{
				  "indexed": true,
				  "internalType": "address",
				  "name": "operator",
				  "type": "address"
				},
				{
				  "indexed": false,
				  "internalType": "bool",
				  "name": "approved",
				  "type": "bool"
				}
			  ],
			  "name": "ApprovalForAll",
			  "type": "event"
			},
			{
			  "anonymous": false,
			  "inputs": [
				{
				  "indexed": true,
				  "internalType": "address",
				  "name": "from",
				  "type": "address"
				},
				{
				  "indexed": true,
				  "internalType": "address",
				  "name": "to",
				  "type": "address"
				},
				{
				  "indexed": true,
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "Transfer",
			  "type": "event"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "to",
				  "type": "address"
				},
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "approve",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "owner",
				  "type": "address"
				}
			  ],
			  "name": "balanceOf",
			  "outputs": [
				{
				  "internalType": "uint256",
				  "name": "",
				  "type": "uint256"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "getApproved",
			  "outputs": [
				{
				  "internalType": "address",
				  "name": "",
				  "type": "address"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "owner",
				  "type": "address"
				},
				{
				  "internalType": "address",
				  "name": "operator",
				  "type": "address"
				}
			  ],
			  "name": "isApprovedForAll",
			  "outputs": [
				{
				  "internalType": "bool",
				  "name": "",
				  "type": "bool"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "name",
			  "outputs": [
				{
				  "internalType": "string",
				  "name": "",
				  "type": "string"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "ownerOf",
			  "outputs": [
				{
				  "internalType": "address",
				  "name": "",
				  "type": "address"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "from",
				  "type": "address"
				},
				{
				  "internalType": "address",
				  "name": "to",
				  "type": "address"
				},
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "safeTransferFrom",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "from",
				  "type": "address"
				},
				{
				  "internalType": "address",
				  "name": "to",
				  "type": "address"
				},
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				},
				{
				  "internalType": "bytes",
				  "name": "_data",
				  "type": "bytes"
				}
			  ],
			  "name": "safeTransferFrom",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "operator",
				  "type": "address"
				},
				{
				  "internalType": "bool",
				  "name": "approved",
				  "type": "bool"
				}
			  ],
			  "name": "setApprovalForAll",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "bytes4",
				  "name": "interfaceId",
				  "type": "bytes4"
				}
			  ],
			  "name": "supportsInterface",
			  "outputs": [
				{
				  "internalType": "bool",
				  "name": "",
				  "type": "bool"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [],
			  "name": "symbol",
			  "outputs": [
				{
				  "internalType": "string",
				  "name": "",
				  "type": "string"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "tokenURI",
			  "outputs": [
				{
				  "internalType": "string",
				  "name": "",
				  "type": "string"
				}
			  ],
			  "stateMutability": "view",
			  "type": "function"
			},
			{
			  "inputs": [
				{
				  "internalType": "address",
				  "name": "from",
				  "type": "address"
				},
				{
				  "internalType": "address",
				  "name": "to",
				  "type": "address"
				},
				{
				  "internalType": "uint256",
				  "name": "tokenId",
				  "type": "uint256"
				}
			  ],
			  "name": "transferFrom",
			  "outputs": [],
			  "stateMutability": "nonpayable",
			  "type": "function"
			}
		  ]
    };
	
	async function mounted() {
		var allDataPromise = await db.collection('holders').get()
		//var allData = allDataPromise.data()

		var arrData = Array.from(allDataPromise.docs.map((e)=>{
			var obj = e.data()
			obj.id = e.id
			return obj
		})).filter(holder => holder.path != null)

		setArtworks(arrData)

		var holderDataPromise = await db.collection('holders').doc(defaultAccount).get()
		var holderData = holderDataPromise.data()
		setHolderData(holderData)

		// console.log('holder data : ', holderData.submission)
		const signer = provider.getSigner();
		const usdcContract = new ethers.Contract(pianoKingNFT.address, pianoKingNFT.abi, signer);
		
        try {
            let nftBalance = await usdcContract.balanceOf(defaultAccount);
            
            nftBalance = ethers.utils.formatUnits(nftBalance, 0);
            //console.log('---->', nftBalance)
            setNFTBalance(nftBalance)

            if (nftBalance) {
                setAuthorized(true)

                // hides modal to enter the site
                hideConnectModal();
            }

            var objHolder = {
                address: defaultAccount,
                number_nft: nftBalance,
				votes: 0,
				crowns: 0
            }

            if (holderData == null) {
                //console.log('nullll')
                
                db.collection('holders').doc(defaultAccount).set(objHolder)
            }

            if (true /* user has already created a fan art */) {
                setAlreadyCreated(true);
            }
        } 
        catch {
            setWelcomeMessage("Beware ! You are not part of the kingdom !");
            setConnectWalletActive(false);
        }

		
	}

    useEffect(() => {
        if(defaultAccount){
            provider.getBalance(defaultAccount)
        
        .then(balanceResult => {
            setUserBalance(ethers.utils.formatEther(balanceResult));
            mounted()
        })
        
        
        };
    }, [defaultAccount]);

    

    const toggleTabs = () => {
        setToggled(!toggled);
        document.querySelector(".tabs-line").classList.toggle("tabs-line-right");
        document.querySelectorAll(".gallery-container, .prince-page").forEach((el) => {
            el.classList.toggle("hide");
        });
    }

    const clickedArtwork = (idx) => {
        setArtworkIdx(idx);
        document.querySelector(".modal-artwork-container").classList.toggle("hide");
    }

    const clickArtworkModal = (e) => {
        document.querySelector(".modal-artwork-container").classList.toggle("hide");
    }

    const clickCreateModal = (e) => {
        if (alreadyCreated && document.querySelector(".modal-create-container").classList.contains("hide")) {
            alert("If you resubmit an art piece, know that it will replace any previously submitted work.");
        }
        document.querySelector(".modal-create-container").classList.toggle("hide");
    }

    const hideConnectModal = (e) => {
        document.querySelectorAll(".modal-connection-container, .nav-buttons").forEach((el) => {
            el.classList.toggle("hide");
        });
    }

    const vote = (idxVoted) => {
		console.log('holderData', holderData)
		console.log('artworksidxVoted', artworks[idxVoted])
        if (holderData.votes < 1 ) {
			
            artworks[idxVoted].crowns += 1;
			
			var votes = holderData.votes + 1
            setArtworks([...artworks]);
			setHolderData(votes)
			db.collection('holders').doc(defaultAccount).update({votes: votes})
			db.collection('holders').doc(artworks[idxVoted].address).update({crowns: artworks[idxVoted].crowns})
        }
    }

	return (
        <div className="container">
            <div className="nav">
                <img src="./piano-logo.png"></img>
                <div className="nav-buttons hide">
                    <Button value="Create" type="secondary" active={true} onClickFunc={clickCreateModal}></Button>
                    <Button value={defaultAccount ? defaultAccount.slice(0,5) + "..." + defaultAccount.slice(-4, -1) : "Please connect"} type="primary" active={true} locked={true}></Button>
                </div>
            </div>

            
            <div className="modal-artwork-container hide">
                <div className="modal-background" onClick={clickArtworkModal}></div>
                <div className="modal">
                    <ArtworkExpanded artworks={artworks} artworkIdx={artworkIdx} onClickFunc={vote} />
                </div>
            </div>

            <div className="modal-connection-container">
                <div className="modal-background"></div>
                <div className="modal">
                    <h3>{welcomeMessage}</h3>
                    <Button value="Connect" type="primary" active={connectWalletActive} locked={!connectWalletActive} onClickFunc={connectWalletHandler}></Button>
                </div>
            </div>

            <div className="modal-create-container hide">
                <div className="modal-background" onClick={clickCreateModal}></div>
                <div className="modal">
				<div className="form-container">
					<h2 className='coral'>Submit your artwork</h2>
					<h3>Title</h3>
					<input type="text" id="title"></input>
					<h3>Artwork</h3>
					<input id="file" type="file"></input>
					<h3>Description</h3>
					<textarea id="description"></textarea>
					<Button value="Submit" type="regular" active={true} locked={false} onClickFunc={validateFieldsAndSendFb}></Button>
				</div>
                </div>
            </div>

            {/* Page */}
			
            <div className="tabs">
                <Button value="Gallery" type="regular" active={toggled} locked={toggled} onClickFunc={!toggled ? toggleTabs : null}></Button>
                <div className='tabs-line-container'>
                    <div className="tabs-line"></div>
                </div>
                <Button value="The Prince" type="regular" active={!toggled} locked={!toggled} onClickFunc={toggled ? toggleTabs : null}></Button>
            </div>
            
            <div className="gallery-container">
                <div className="gallery">
                    <GetArtworks artworks={artworks} clickedArtwork={clickedArtwork}></GetArtworks>
                </div>
            </div>

            <div className="prince-page hide">
                <div className="prince-page-text">
                    <h1 className="coral">WILL YOU BE THE NEXT PRINCE ?</h1>
                    <h2>3 WEEKS</h2>
                    <h1 className="coral">LEFT</h1>
                </div>
                <img src="kings.gif" className="img-king-bg"></img>
            </div>
{/* 			<img src="https://firebasestorage.googleapis.com/v0/b/app-kingdom.appspot.com/o/celim.png?alt=media&token=8b2d6184-c7c7-463d-9b55-98a270a58f5e" />
 */}
			
		

            <div className="date-selector">

            </div>
			
            {/* <div className='walletCard'>
                <h4> Connection to MetaMask using ethers.js </h4>
                <button onClick={connectWalletHandler}>{connButtonText}</button>
                <div className='accountDisplay'>
                    <h3>Address: {defaultAccount}</h3>
                </div>
                <div className='balanceDisplay'>
                    <h3>Balance: {userBalance}</h3>
                    <h3>NFT Balance : {nftBalance}</h3>
                    <AuthorizedContent authorized={authorized}/>
                </div>
                {errorMessage}


				Title
				<input id="title"></input><br />
				File
            	<input id="file" type="file"></input><br />
				Description
				<input id ="description"></input><br/>
				<button onClick={validateFields}>Create</button>
				
            </div> */}
			</div>
	);
}

export default WalletCardEthers;