import React, {useState, useEffect} from 'react'
import './ArtworkExpanded.css'
import Button from './Button'

const ArtworkExpanded = ({artworks, artworkIdx, onClickFunc}) => {

    const click = () => {
        onClickFunc(artworkIdx);
    }

    //console.log(artworks);

    const ReadFile = () => {
        if (artworks[artworkIdx]?.type == "video/mp4") {
            return <><video controls width="500"><source src={`${process.env.REACT_APP_URL}${artworks[artworkIdx]?.path}?alt=media&token=${process.env.REACT_APP_TOKEN}`} type="video/mp4" />            </video></>
        } else {
            return <img src={`${process.env.REACT_APP_URL}${artworks[artworkIdx]?.path}?alt=media&token=${process.env.REACT_APP_TOKEN}`}  />
        }
    }

    return (
        <div className="artwork-expanded">
            <ReadFile />
            <h3>
                <span>{artworks[artworkIdx]?.title} - </span>
                <span class="weight-normal">@{artworks[artworkIdx]?.author}</span>
                <span className="artwork-expanded-crowns">{artworks[artworkIdx]?.crowns}</span>
                <svg width="34" height="21" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.06412 7.22015C9.98139 7.22015 10.721 6.60623 10.721 5.84735C10.721 5.08847 9.98139 4.47455 9.06412 4.47455C8.153 4.47455 7.41138 5.08847 7.41138 5.84735C7.41138 6.60623 8.15403 7.22015 9.06412 7.22015Z" fill="#D42C5F"/>
                    <path d="M16.6244 4.70953C18.1929 4.70953 19.4636 3.65697 19.4636 2.35619C19.4636 1.05541 18.1929 0 16.6244 0C15.06 0 13.7874 1.05446 13.7874 2.35524C13.7874 3.65602 15.059 4.70953 16.6244 4.70953Z" fill="#D42C5F"/>
                    <path d="M24.813 7.3006C25.7269 7.3006 26.4678 6.68556 26.4678 5.92688C26.4678 5.1682 25.7269 4.55316 24.813 4.55316C23.8991 4.55316 23.1582 5.1682 23.1582 5.92688C23.1582 6.68556 23.8991 7.3006 24.813 7.3006Z" fill="#D42C5F"/>
                    <path d="M26.8713 22.3358H6.37793V27.0453H26.8713V22.3358Z" fill="#D42C5F"/>
                    <path d="M31.1628 7.77142C32.7296 7.77142 33.9998 6.71738 33.9998 5.41714C33.9998 4.11691 32.7296 3.06287 31.1628 3.06287C29.5959 3.06287 28.3257 4.11691 28.3257 5.41714C28.3257 6.71738 29.5959 7.77142 31.1628 7.77142Z" fill="#D42C5F"/>
                    <path d="M5.67314 5.50244C5.67314 4.20074 4.4056 3.14819 2.834 3.14819C1.27165 3.14725 0 4.20074 0 5.50244C0 6.80225 1.27165 7.85669 2.83503 7.85669C4.4056 7.85669 5.67314 6.80225 5.67314 5.50244Z" fill="#D42C5F"/>
                    <path d="M27.9695 8.20821L24.0837 11.4331L24.4103 8.14758C24.4103 8.14758 23.8762 7.40577 23.1787 8.00547L20.8234 10.7813L17.7583 6.32763C17.7583 6.32763 16.9139 5.53182 15.4872 6.32763L12.496 10.6705L10.4694 8.00641C10.4694 8.00641 9.95167 7.25134 9.29941 8.05473L9.51204 11.6387L5.68167 8.20821C5.68167 8.20821 4.49835 7.76767 3.93237 8.82875L6.40789 21.394H26.8355L30.2396 9.15181C30.2396 9.15181 29.8585 7.53272 27.9695 8.20821Z" fill="#D42C5F"/>
                </svg>
            </h3>
            <p>
                {artworks[artworkIdx]?.description}
            </p>
            <Button value="Crown" type="regular" active={true} onClickFunc={click}></Button>
        </div>
    );
}

export default ArtworkExpanded;