import React, {useState, useEffect} from 'react'
import './Artwork.css'

const Artwork = ({idx, place, onClickFunc, artworks}) => {

    const click = () => {
        onClickFunc(idx);
    }

    let fillColor;
    
    if (place == 1) {
        fillColor = "#ffd700";
    } else if (place == 2) {
        fillColor = "#d1d1d1";
    } else if (place == 3) {
        fillColor = "#c77c30";
    } else {
        fillColor = "#D42C5F";
    }

    const ReadFile = () => {
        if (artworks[idx]?.type == "video/mp4") {
            return <><video width="350"><source src={`${process.env.REACT_APP_URL}${artworks[idx]?.path}?alt=media&token=${process.env.REACT_APP_TOKEN}`} type="video/mp4" />            </video></>
        } else {
            return <img src={`${process.env.REACT_APP_URL}${artworks[idx]?.path}?alt=media&token=${process.env.REACT_APP_TOKEN}`}/>
        }
    }

    return (
        <div className={`artwork-container`} onClick={click}>
            <ReadFile />

            <div className="artwork-crowns">
                <span>{artworks[idx]?.crowns}</span>
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.70932 3.70356C4.0847 3.70356 4.38736 3.43117 4.38736 3.09446C4.38736 2.75775 4.0847 2.48536 3.70932 2.48536C3.33646 2.48536 3.03296 2.75775 3.03296 3.09446C3.03296 3.43117 3.33688 3.70356 3.70932 3.70356Z" fill={fillColor}/>
                    <path d="M6.80324 2.58961C7.44513 2.58961 7.96512 2.12259 7.96512 1.54543C7.96512 0.96828 7.44513 0.5 6.80324 0.5C6.16304 0.5 5.64221 0.96786 5.64221 1.54501C5.64221 2.12217 6.16262 2.58961 6.80324 2.58961Z" fill={fillColor}/>
                    <path d="M10.1544 3.73929C10.5284 3.73929 10.8316 3.4664 10.8316 3.12977C10.8316 2.79314 10.5284 2.52025 10.1544 2.52025C9.78037 2.52025 9.47717 2.79314 9.47717 3.12977C9.47717 3.4664 9.78037 3.73929 10.1544 3.73929Z" fill={fillColor}/>
                    <path d="M10.9967 10.4104H2.61011V12.5H10.9967V10.4104Z" fill={fillColor}/>
                    <path d="M12.753 3.94821C13.3942 3.94821 13.914 3.48053 13.914 2.90362C13.914 2.3267 13.3942 1.85902 12.753 1.85902C12.1117 1.85902 11.5919 2.3267 11.5919 2.90362C11.5919 3.48053 12.1117 3.94821 12.753 3.94821Z" fill={fillColor}/>
                    <path d="M2.32164 2.94145C2.32164 2.36387 1.80292 1.89685 1.15977 1.89685C0.520405 1.89643 0 2.36387 0 2.94145C0 3.51818 0.520405 3.98604 1.16019 3.98604C1.80292 3.98604 2.32164 3.51818 2.32164 2.94145Z" fill={fillColor}/>
                    <path d="M11.4461 4.142L9.85586 5.5729L9.98954 4.11509C9.98954 4.11509 9.77095 3.78595 9.48553 4.05204L8.52164 5.28369L7.26729 3.30758C7.26729 3.30758 6.92175 2.95448 6.33788 3.30758L5.11379 5.23451L4.28442 4.05246C4.28442 4.05246 4.07256 3.71743 3.80563 4.0739L3.89265 5.66412L2.32513 4.142C2.32513 4.142 1.84087 3.94653 1.60925 4.41733L2.62232 9.99256H10.982L12.3751 4.56068C12.3751 4.56068 12.2191 3.84228 11.4461 4.142Z" fill={fillColor}/>
                </svg>
            </div>
        </div>
    );
}

export default Artwork;